.map-container {
    position: relative;
    padding: 20px;
  .address-heading {
    font-size: 16px;
    color: black;
    margin: 0;
    font-weight: 500;
  }

  .map-input {
    background-color: #fff;
    height: 40px;
    font-size: 15px;
    padding: 0 11px 0 13px;
    text-overflow: ellipsis;
    width: 100%;
    max-width: 500px;
    border-radius: 4px;
    z-index: 999;
    left: 74px;
    top: 0;
  }
  .btn-section {
    position: relative;
  }
  .update-btn {
    margin-right: 20px;
  }

  .location-search-input {
    background-color: #fff;
    height: 40px;
    font-size: 15px;
    padding: 0 11px 0 13px;
    text-overflow: ellipsis;
    width: 100%;
    max-width: 500px;
    border-radius: 4px;
    z-index: 999;
    left: 24px;
  }
  .autocomplete-dropdown-container {
    background-color: #fff;
    font-size: 15px;
    padding: 0 11px 0 13px;
    text-overflow: ellipsis;
    width: 80%;
    max-width: 500px;
    border-radius: 4px;
    z-index: 999;
    position: absolute;
    left: 24px;
    top: 60px;
  }
  .loader-on-save {
    position: absolute;
    z-index: 999;
    top: 12%;
    right: 85%;
  }
  .submit-button {
    border-radius: 4px;
    position: absolute;
    z-index: 1;
    left: 54%;
    top: 23%;
  }
  .edit-icon {
    color: #fff !important;
    font-size: 14px !important;
  }
  .location-bar {
    display: inline;
  }
  .location-bar-hide {
    display: none;
  }
}
.gmnoprint {
  display: none !important;
}
div#map {
  height: 300px;
}