.voucher-main {
    .vouchercard-main {
        background: #FFFFFF;
        border-radius: 16px;
        padding: 40px 20px;
        margin: 0px auto;
        width: 50%;

        .voucher-heading-main {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            color: #000000;
        }

        .voucher-form-group {
            background: #F1F3F4;
            border-radius: 8px;
            border: 1px solid #F1F3F4;
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            color: #413D3D;
        }
    }

    .save-btn-float {
        text-align: center;
        margin-top: 30px;
    }
}

@media only screen and (min-width: 786px) and (max-width: 999px) {
    .voucher-main .vouchercard-main {
        width: 100%;
    }
}

@media only screen and (max-width: 786px) {
    .voucher-main .vouchercard-main {
        width: 100%;
    }
}