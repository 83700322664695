@import "../../assets/scss/variables.scss";
//add client css

.setting-main {
  .tab-flex-main {
    display: flex;
    gap: 15px;
    margin: 25px 0px 30px;
  }

  .setting-topcard-main {
    padding: 40px 24px;
    background: #ffffff;
    border-radius: 16px;
    margin-bottom: 40px;

    .setting-heading {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      color: #131313;
    }

    .setting-text {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      color: #413d3d;
    }

    .setting-form-group {
      border: 1px solid #f1f3f4;
      background: #f1f3f4;
      border-radius: 8px;
    }
  }

  .save-btn-float {
    float: right;
    padding-bottom: 50px;

    .save-btn {
      color: #fff;
      display: inline-block;
      background: #d60000;
      border-radius: 5px;
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      padding: 10px 40px;
      cursor: pointer;
    }
  }
  .notification-list {
    display: flex;
    border: 1px solid #d60000;
    box-shadow: 0 2px 4px rgba(214, 0, 0, 0.3); 
    border-radius: 8px; 
    width: 50%;
    margin-bottom: 13px;
    img {
      width: 50px;
      height: 50px;
      margin: 5px;
      text-align: center;
    }
    .notification-details {
      margin-top: 5px;
      .user {
        margin-bottom: 0;
        font-family: "Montserrat";
        font-style: bold;
        font-weight: 600;
      }
      
      .time{
        font-size: 14px;
        color: #413d3d;
      }
      
    }
    
  }
}

.msg-bottom {
  border-radius: 15px;
  display: block;
  height: 60px;
  font-size: 1rem;
  line-height: 1.5;
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border: 0.5px solid #d60000;

  .input-group {
    padding-left: 10px;
    width: 100% !important;
    height: 50px;
    display: flex;

    .text-area {
      width: 91%;

      .form-control {
        border: none !important;
        border-radius: 15px !important;
        display: block;
        height: 56px;
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        line-height: 2.5;
        background-clip: padding-box;
        transition: border-color 0.15s ease-in-out,
          box-shadow 0.15s ease-in-out;
      }
    }
    .send-btn {
      height: 40px;
      width: 47px;
      margin: 8px 5px;
      background-color: #f03030;
      border: none;
      border-radius: 10px;
      .tele-icon {
        height: 30px;
        width: 35px;
        padding-right: 11px;
      }
    }
  }
}
.css-1dimb5e-singleValue{
  padding: 1px 4px;
}

@media only screen and (min-width: 786px) and (max-width: 999px) {
  .setting-main .tab-flex-main {
    flex-wrap: wrap;
    justify-content: center;
  }
  .tabs-main {
    width: 45%;
  }
}
@media only screen and (max-width: 786px) {
  .setting-main .tab-flex-main {
    flex-wrap: wrap;
    justify-content: center;
  }
}
@media only screen and (max-width: $breakpoint-mobile) {
  .setting-main {
    .tab-flex-main {
      flex-wrap: nowrap;
      overflow-x: scroll;
      justify-content: unset;
      flex-direction: column;
    }
  }
}
