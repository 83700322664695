@import "../../assets//scss/variables.scss";
.menu-main {
  h3 {
    margin: 0 !important;
  }
  .categories-and-items{
    margin-top: 2em;
  }
  .each-category {
    background: #f5f2ed;
    border-radius: 5px;
    // box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    width: 100%;
    p {
      margin: 0 !important;
    }
    .menu_card_header {
      font-weight: 500;
      font-size: 1.2rem;
      color: #000000;
    }
  }
  .item-icon{
    width: 1.25rem;
    height: 1.25rem;
  }
}
.modal-header-custom {
  padding: 2.5rem 2rem;
  border: none;
}
.modal-body-custom {
  padding-top: 0;
}
.addCategory-main {
  margin: 0 40px 0 20px;

  .heading-text {
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 14px;
    line-height: 17.07px;
    color: "#131313";
  }

  .addCategory-input-form {
    font-family: "Montserrat";
    font-size: 14px;
    color: #413d3d;

    .input-container {
      margin-top: 16px;
      color: #413d3d;

      .input-title {
        display: flex;
        justify-content: space-between;
        margin-left: auto;
      }
       .uploaddocument-group {
        border-color: $primaryColor;
        margin-left: 0.5em;
        border-radius: 8px;
      }
    }

    Input, select,
    .input-textarea {
      background-color: #f5f2ed;
      border: none;
      margin-left: 0.5rem;
      font-size: 14px;
      border-radius: 8px;
      padding: 1rem;
    }
    .errors-after-sumbit {
      color: $primaryColor;
      text-align: center;
      font-weight: 600;
      font-family: "Montserrat";
    }
    .save-btn-container {
      display: flex;
      justify-content: center;
      margin-top: 30px;

      .save-btn {
        width: 200px;
      }
    }
  }
   
}
