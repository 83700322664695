@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap");
@import "./assets/scss/variables.scss";

:root {
  --primaryColor: "#D60000";
  --primaryBackground: "#F5F2ED";
}

.error {
  color: $primaryColor;
  font-size: 12px;
  margin-left: 5px;
}

Input,
select {
  background-color: #edf0f7 !important;
  box-shadow: none !important;
}

.form-control:focus {
  box-shadow: none !important;
}

.react-bootstrap-table-main {
  .react-bootstrap-table {
    .table-bordered {
      tr,
      th,
      td {
        border: none !important;
        border-width: 0px;
      }
    }
  }
}
.content {
  .table-main {
    thead {
      th {
        color: #000000 !important;
        font-size: 14px;
        line-height: 14.63px;
        font-weight: 300;
        font-family: 
        Montserrat;
      }
    }
    tbody {
      td {
        color: #4d5d6b !important;
        font-size: 13px;
        line-height: 14.63px;
        font-weight: 500;
      }
    }
  }
}
.no-data-div {
  text-align: center;
  p {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 1.5rem;
    color: #000000;
  }
}

.modal-header {
  .btn-close {
    margin: 0 !important;
  }
}

.image-cropper-main {
  position: relative;
  .image-cropper {
    background: "#333";
    .reactEasyCrop_Container {
      margin: 0 auto;
    }
  }
  .controls {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    gap: 20px;
  }

  .zoom-range {
    height: 2px;
    border-top: 5px solid #fff;
    border-bottom: 5px solid #fff;
    background: #3f51b5;
    width: 100%;
  }

  .zoom-range::-moz-range-thumb {
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 1px solid #3f51b5;
    background: #3f51b5;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  .zoom-range::-webkit-slider-thumb {
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 1px solid #3f51b5;
    background: #3f51b5;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  .controls:hover input[type="range"]::-webkit-slider-thumb {
    box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
    border-radius: 50%;
  }

  .controls:hover input[type="range"]::-moz-range-thumb {
    box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
  }
}
