.map-inputsection {
    position: relative;
    .location {
      background-color: transparent;
      position: relative;
      .disabled {
        background-color: lightgray !important;
      }
      Input {
        border-radius: 8px;
        font-weight: 500;
        width: 100%;
        padding-right: 2rem;
        cursor: pointer;
      }
      .map {
        width: 1.8rem;
        margin-block: 0;
        position: absolute;
        right: 0.5em;
        top: 0.2em;
      }
    }
  }