//add client css
@import "../../assets/scss/variables.scss";

.client-main {
    .client-heading-main {
        font-family: 'Montserrat';
        font-variant: small-caps;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        color: #000000;
        margin: 0;
    }

    .client-topcard-main {
        padding: 40px 24px;
        background: #FFFFFF;
        border-radius: 16px;
        margin-top: 60px;

        .client-heading {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            color: #131313;
        }

        .user-form-group {
            border: 1px solid #F1F3F4;
            background: #F1F3F4;
            border-radius: 8px;

        }
    }

    .class-clientcard-flex {
        display: flex;
        gap: 30px;

        .client-bottomcard-main {
            padding: 40px 24px;
            background: #FFFFFF;
            border-radius: 16px;
            width: 50%;
            margin: 30px 0px 50px;

            .client-heading {
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                color: #131313;
            }

            .user-form-group {
                border: 1px solid #F1F3F4;
                background: #F1F3F4;
                border-radius: 8px;

            }

            .add-client-btn {
                background: #2F49D1;
                border-radius: 8px;
                padding: 10px 15px;
                color: #FFFFFF;
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                display: inline-block;
            }
        }
    }

    .save-btn-float {
        float: right;
        padding-bottom: 50px;
    }
}
@media only screen and (min-width: 786px) and (max-width: 999px){
    .client-main .class-clientcard-flex .client-bottomcard-main{
        width: 100%;
    }
    .client-main .class-clientcard-flex{
        display: block;
    }
}
@media only screen  and (max-width: $breakpoint-tablet) {
    .client-main .class-clientcard-flex .client-bottomcard-main{
        width: 100%;
    }
    .client-main .class-clientcard-flex{
        display: block;
    }
}