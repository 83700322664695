@import "../../assets/scss/variables.scss";

.profile-main {
  .profile-heading-main {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    font-variant: small-caps;
    color: #000000;
  }
  .profile-input-form {
    font-family: "Montserrat";
    Form {
      margin-bottom: 6em;
      position: relative;
    }
    .formbox {
      padding: 2em;
      display: flex;
      justify-content: space-between;
      .textdescription {
        width: 30%;
        .title {
          font-size: 1.3rem;
          font-weight: 600;
        }
      }
      .inputsection {
        width: 50%;
        background-color: #ffffff;
        border-radius: 8px;
        padding: 1.5em 2em;
        .title {
          font-size: 1.3rem;
          font-weight: 600;
        }
        .profile-image-div {
          display: flex;
          flex-direction: column;
          margin: 0 auto;
          align-items: center;
          gap: 0.5em;
          margin-bottom: 1em;
          img {
            width: 10rem;
            height: 10rem;
            object-fit: cover;
            object-position: center;
            border-radius: 50%;
          }
          .change {
            font-family: 'Montserrat';
            font-size: 0.9rem;
            font-weight: 600;
            width: fit-content;
            color: #4960D7;
            cursor: pointer;    
          }
        }    
        Input, .select {
          border-color: #f92323;
          padding-block: 0.7em;
          box-shadow: none;
          background-color: #ffffff !important;
        }
        .disabled {
          background-color: $primaryBGColor !important;
        }
        .input-icon-group {
          position: relative;
          border:1px solid #f92323;
          border-radius: 8px;
          display: flex;
          align-items: center;
          Input {
            width: 90%;
            float: right;
            flex: 1;
            border: none;
          }
          .icon {
            font-size: 1.5rem;
            margin-inline: 0.3em;
            cursor: pointer;
          } 
        }
      } 
    }
    .documents {
      .textdescription {
        .existing-preview {
          .previewdoc {
            width: 200px;
            height: 100px;
            object-fit: cover;
            margin-bottom: 1em;
          }
        }
      }
      .inputsection {
        .title {
          font-weight: 600;
          font-size: 1.2rem;
          margin-block: 0 1em;
        }
        .input-icon-group {
          border-style: dashed;
          display: flex;
          flex-direction: column;
          justify-content: center;
          gap: 0.7em;
          padding: 2em 1em;
          margin-bottom: 1em;
          .upload-preview {
            font-family: 'Montserrat';
            font-size: 1rem;
            font-weight: 500;
            list-style: none;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 0.5em;
            margin-bottom: 1.5em;
            .preview {
              width: 100px;
              height: 100px;
            }
            img {
              width: 100%;
              object-fit: cover;
            }
          }
          .addicon {
            cursor: pointer;
          }
        }
      }
    }
    .address {
      .textdescription{
        .existing-preview {
          margin-bottom: 1em;
          .list-scroll{
            padding-right: 10px;
            .address-box {
              background-color: #ffffff;
              padding: 1em;
              border-radius: 8px;
              width: 100%;
              position: relative;
              margin-bottom: 1em;
              .editicon {
                position: absolute;
                right: 1em;
                bottom: 1em; 
                cursor: pointer;
                font-size: 1.2rem;
                color: $primaryColor;
              }
              .badge {
                background-color: $primaryColor !important;
              }
            }
          }
        }
      }
      .inputsection {
        position: relative;
        .addnew-btn {
          position: absolute;
          right: 2em;
          top: 2em;
        }
        .location {
          background-color: transparent;
          Input {
            border-radius: 8px;
            font-weight: 500;
            width: 100%;
            padding-right: 2rem;
            cursor: pointer;
          }
          .map {
            width: 1.8rem;
            margin-block: 0;
            position: absolute;
            right: 0;
          }
        }
      }
    }
  }

  .save-btn-float {
    position: absolute;
    right: 2em;
  }
}

@media screen and (max-width: $breakpoint-tablet) {
  
  .profile-main {
    .profile-input-form {
      .formbox {
        flex-direction: column;
        padding: 1em 0;
        .textdescription {
          width: 100%;
        }
        .inputsection {
          width: 100%;
          padding: 1em;
          .addnew-btn{
            top: 1em;
            right: 1em;
          }
        }
      }
      .save-btn-float {
        right: 0;
      }
    }
    
  }
}