@import "../../assets/scss/variables";

// .register-main {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   height: 100vh;
//   @media (max-width: $breakpoint-tablet) {
//     height: 100%;
//     flex-direction: column-reverse;
//     .register-form-main {
//       min-width: 350px;
//     }
//     .company-logo-main {
//       min-width: 350px;
//     }
//   }
//   .register-form-main {
//     width: 50%;
//     padding: 2em 2em;
//     .register-form {
//       width: 100%;
//       max-width: 350px;
//       margin: 0 auto;
//       .logo-img {
//         width: 80px;
//         margin: 0 auto 64px;
//         text-align: center;
//         img {
//           width: 100%;
//           height: auto;
//         }
//       }
//       Input {
//         background-color: #e8e8e8 !important;
//       }
//       .text-input {
//         background-color: #e8e8e8 !important;
//         border-radius: 8px !important;
//         color: #000;
//         border: 0 !important;
//         padding: 10px;
//       }
//       .text-input:focus {
//         box-shadow: none;
//       }
//       .text-input-group:focus {
//         box-shadow: none;
//       }
//       .password-input-group {
//         border-radius: 8px;
//         background-color: #e8e8e8;
//       }
//       .text-input-group {
//         border-radius: 8px !important;
//         background-color: transparent;
//         border: 0;
//         padding: 10px;
//         color: #000;
//       }
//       .input-text-icon {
//         background-color: transparent;
//         border: 0px;
//         border-radius: 0 !important;
//         cursor: pointer;
//       }
//       .input-check:checked {
//         border-color: #f92323 !important;
//         background-color: #fff;
//       }
//       .input-check:focus {
//         border-color: #f92323 !important;
//         box-shadow: none;
//       }
//       .input-check:checked {
//         background-image: url("../../assets/images/icons/tick.svg");
//       }
//       .login-link {
//         padding-top: 1em;
//         padding-bottom: 0;
//         margin-bottom: 0;
//         .link {
//           text-decoration: none;
//           color: #f92323;
//           font-weight: 500;
//         }
//       }
//       .error-message {
//         text-align: center;
//         font-weight: 500;
//         color: #f92323;
//         padding: 1em 0 0 0;
//         margin: 0;
//       }
//       .register-btn {
//         width: 100%;
//         margin-top: 24px;
//         font-weight: 500;
//       }
//       .login-or-content-flex {
//         align-items: center;
//         justify-content: center;
//         padding-top: 4em;
//         .horizontal-line {
//           border-bottom: 0.5px solid #413d3d;
//         }
//         .login-or-content-main {
//           text-align: center;
//         }
//       }
//       .login-grey-btn-flex {
//         align-items: center;
//         display: flex;
//         gap: 20px;
//         padding-top: 40px;
//         .login-grey-icon-btn {
//           background: #e8e8e8 !important;
//           background: red;
//           border: 2px solid #e8e8e8 !important;
//           border-radius: 8px !important;
//           font-family: "Montserrat";
//           font-style: normal;
//           font-weight: 600;
//           font-size: 14px;
//           color: #000000;
//           width: 50%;
//           text-align: left;
//         }
//       }
//     }
//   }
//   .company-logo-main {
//     width: 50%;
//     padding: 2em;
//     vertical-align: middle;
//     img {
//       object-fit: contain;
//       width: 100%;
//     }
//   }
// }
//new scss
.product-main {
  background-color: #edf0f7;
  padding: 20px;
}
.add-customer-tabs {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  padding: 40px, 100px, 40px, 100px;
  justify-content: space-around;
  list-style-type: none;
  border-radius: 20px;
  .add-customer-each-tab {
    padding: 10px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    .from-page-number {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #edf0f7;
      color: #a7a8ab;
    }
    p {
      margin-top: 10px;
      color: #a7a8ab;
      font-size: 12px;
    }
  }
}

.type-tabs {
  list-style-type: none;
  .type-each-tab {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    border: 1px solid black;
    border-radius: 10px;
    margin: 5px;
    padding: 10px;
    cursor: pointer;
  }
}

.active-tab {
  background-color: white;
  color: #2c82c9;
}
.active-type-tab {
  border: 1px solid #ec700c !important;
  color: #ec700c !important;
}
.active-top-n-bottom-tab {
  border: 1px solid #ec700c !important;
  color: #ec700c !important;
}
.generating-bill-main {
  .bill-div {
    width: 800px;
    height: 600px;
    margin: 0 auto;
    background: white;
    border-radius: 10px;
    padding: 20px;
  }
  .print-now-div {
    background-color: #f59c1e;
    padding: 10px;
    color: white;
  }
  .bottom-div {
    margin-top: 40px;
    //  margin-left: 500px;
    width: 800px;
    padding: 20px;
    border-radius: 10px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    background-color: white;
  }
}
.tabs-row {
  margin-top: 40px;
}

.button-submit {
  display: flex;
  justify-content: space-between;
  width: 20%;
}

.add-customer-each-tab.active {
  background-color: rgb(255, 255, 255);
  .from-page-number {
    background-color: #56e20a;
    color: #ffffff;
  }
  p {
    color: #56e20a;
  }
}

.headings {
  width: auto;
  height: 18px;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #a7a8ab;
}
.product-form-group::placeholder {
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  color: #a7a8ab;
}

.Agripro {
  width: auto;
  height: auto; // 24px;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  font-weight: bold;
}
.Instock {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  width: auto;
  height: 18px;
  color: #f59d00;
}

.paragraphAgripro {
  width: auto;
  height: auto; //57px;
  max-height: 57px;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #a7a8ab;
  overflow: hidden;
}
.paragraph21 {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  width: auto;
  height: 38px;
}
.Brand {
  width: auto;
  height: 18px;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  font-weight: bold;
}

.colon-seperator{
  
}

.Agr {
  width: auto;
  height: 18px;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #a7a8ab;
}

.product-features{
  .Agr{
    height: auto;
  }
}

.unorder {
  display: grid;
  // grid-template-columns: repeat(auto-fill, minmax(772px, 1fr));
  grid-auto-rows: minmax(103px, auto);
  gap: 8px;
}

.ImageSlider {
  width: auto !important; /* Set the width to 100% to fill the container */
  height: 500px !important; /* Allow the height to adjust proportionally */
  object-fit: cover !important;
  margin-right: 40px;
}

.product-details-right-edit-main {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 70px;
  gap: 1em;
  .product-details-right-edit-btn {
    background: #2f49d1;
    border-radius: 5px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #ffffff;
    border: none;
    padding: 8px 50px;
  }
}
