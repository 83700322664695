@import "../../assets/scss/variables.scss";

.auth-layout {
  display: flex;
  margin: 0;
  padding: 0;
  .sidebar-layout {
    width: 260px;
    height: 100vh;
    position: sticky;
    top: 0;
    left: 0;
    background-color: #000000!important;
  }
  .content-main {
    position: relative;
    width: calc(100% - 112px);
    // background-color: $primaryBGColor;
    background-color: #EDF0F7;
  }
  .content-layout {
    padding: 20px;
  }
}
.no-auth-layout {
  margin: 0;
  padding: 0;
}

@media screen and (max-width: $breakpoint-mobile) {
  .auth-layout {
    display: unset;
    position: relative;
    margin: 0;
    padding: 0;
    .sidebar-layout {
      position: absolute;
      bottom: 0;
      width: 100vw;
      padding: 0;
      height: auto;
      z-index: 1;
      top: unset;
      left: unset;
    }
    .content-main {
      position: relative;
      width: 100%;
      height: 100vh;
      padding-bottom: 10em;
      overflow-y: scroll;
      background-color: $primaryBGColor;
      overflow-x: hidden;
    }
    .content-layout {
      padding: 20px;
    }
  }
}