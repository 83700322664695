.uploaddocument-group {
    border-style: dashed;
    border-width: 1px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.7em;
    padding: 2em 1em;
    margin-bottom: 1em;
    background: #F9F9F9;
    img {
        object-fit: contain;
    }
    .upload-preview {
      font-family: 'Montserrat';
      font-size: 1rem;
      font-weight: 500;
      list-style: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 0.5em;
      margin-bottom: 1.5em;
      .preview {
        width: 100%;
        height: 300px;
      }
      img {
        width: 100%;
        object-fit: cover;
      }
    }
    .addicon {
      cursor: pointer;
    }
  }