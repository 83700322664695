.custom-select {
  ul {
    padding: 0;
    list-style: none;
  }
  font-size: 14px;
  width: 100%;
  position: relative;
  width: 100%;
  background: #edf0f7;
  border-radius: 8px;
  border: 1px solid #edf0f7;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #413d3d;
  display: flex;
  align-items: center;
  padding: 2px 4px;
  justify-content: space-between;
  .options-input {
    display: flex;
    flex-wrap: wrap;
    cursor: pointer;
  }


  .selected-option {
    display: flex;
    min-width: 0px;
    background-color: rgb(230, 230, 230);
    border-radius: 2px;
    margin: 2px;
    box-sizing: border-box;
    align-items: center;
    border-radius: 2px;
    padding: 2px;
    .selected-option-label {
      font-size: 12px;
    }
    .delete-cross {
      cursor: pointer;
      margin-left: 2px;
    }
  }

  .select-input {
    background: #edf0f7 !important;
    border: none !important;
    box-shadow: none !important;
    width: 100%;
  }
  .select-input::placeholder{
    font-size: 14px;
  }
  .select-input:focus-visible,
  .select-input:focus,
  .select-input:active {
    box-shadow: none !important;
    border: none !important;
    outline: none !important;
  }
  .custom-list {
    background: #fff;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    position: absolute;
    border: 1px solid #cccccc70;
    border-radius: 4px;
    left: 0;
    z-index: 999;
    max-height: 200px;
    min-height: 30px;
    overflow-y: scroll;
    padding: 10px 8px;
    width: 100%;
    .ul-list {
      padding: 0;
      list-style: none;
      &.submenu {
        margin-left: 8px;
      }
      .no-child-item {
        cursor: pointer;
        padding: 4px 6px;
        &:hover {
          background: #cccccc70;
        }
      }
      .is-parent {
        .parent-label {
          color: #607274;
          text-transform: capitalize;
          font-weight: 500;
        }
      }
      .no-child-item {
        cursor: pointer;
        &:hover {
          background: #cccccc70;
        }
      }
      .list-item {
        text-transform: capitalize;
      }
    }
    .divider {
      margin: 5px 0;
    }
  }
  .delete-arrow-icons {
    -webkit-box-align: center;
    align-self: stretch;
    display: flex;
    flex-shrink: 0;
    box-sizing: border-box;
    justify-content: flex-end;
    align-items: center;
    .separator {
      align-self: stretch;
      width: 1px;
      background-color: rgb(204, 204, 204);
      margin-bottom: 8px;
      margin-top: 8px;
      box-sizing: border-box;
    }
    .delete-icon{
        display: flex;
        transition: color 150ms ease 0s;
        color: rgb(204, 204, 204);
        padding: 8px;
        box-sizing: border-box;
        cursor: pointer;
        &.active {
            color: #000;
          }
          &:hover {
            color: rgb(110, 110, 110);
          }
    
          .icon-svg {
            display: inline-block;
            fill: currentcolor;
            line-height: 1;
            stroke: currentcolor;
            stroke-width: 0;
          }
    }
    .arrow-icon {
      cursor: pointer;
      display: flex;
      transition: color 150ms ease 0s;
      color: rgb(204, 204, 204);
      padding: 8px;
      box-sizing: border-box;
      &.active {
        color: #000;
      }
      &:hover {
        color: rgb(110, 110, 110);
      }

      .icon-svg {
        display: inline-block;
        fill: currentcolor;
        line-height: 1;
        stroke: currentcolor;
        stroke-width: 0;
      }
    }
  }
  .no-options{
    display: flex;
    justify-content: center;
    align-items: center;
    .no-options-text{
        font-weight: 500;
        font-size: 14px;
        text-transform: capitalize;
        color: #858585;
    }
  }
}
