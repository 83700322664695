@import "../../assets/scss/variables.scss";
@import "../../assets/scss/mixins.scss";

.user-details-main {
    height: inherit;
    position: relative;
    .heading {
        display: flex;
        justify-content: space-between;
        .close-icon {
            cursor: pointer;
        }
    }
    .details-container {
        background-color: #FFFFFF;
        border-radius: 16px;
        padding: 2.5em;
        margin-top: 2em;
        position: relative;
        .status-to-right {
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            .sticky-div {
                position: relative;
                height: 100%;
                padding: 2.5em;  
                .chat-icon{
                    background: #e1dfe2;
                    padding: 10px 15px;
                    border-radius: 8px;
                   }  
                   .chat-icon:hover {
                    background: rgba(214, 0, 0, 0.15);
                   }
                .status {
                    position: sticky;
                    top: 1em;
                    right: 0;
                    border-radius: 8px;
                    backdrop-filter: blur(10px);
                    display: flex;
                    align-items: baseline;
                    .subtitle {
                        display: inline;
                        margin-right: 1em;
                    }
                    .current-status {
                        display: inline;
                        margin-inline: 1em;
                        span {
                          padding: 0.5em 2em;
                          font-weight: 600;
                          text-align: center;
                          border-radius: 16px;
                          text-transform: capitalize;
                        }
                        @include status-styling;
                    }
                    
                }
            }
            
        }
        
        .line {
            border-top: 2px solid black;
            margin-block: 2em;
        }
        .section {
            font-family: 'Montserrat';
            .title {
                font-weight: 500;
                font-size: 1.5rem;
                text-transform: capitalize;
                margin-bottom: 1em;
                color: #131313;
            }
            .subtitle {
                font-weight: 500;
                font-size: 1rem;
                color: #131313;
                line-height: 19.5px;
                margin-bottom: 0.5em;
            }
            p {
                font-size: 0.9rem;
                font-weight: 400;
                color:#413D3D;
            }
        }
        .documents {
            
            .each-document {
                display: inline-block;
                margin-right: 1em;
                overflow: hidden;
                position: relative;
                .view {
                    position: absolute;
                    opacity: 0;
                    width: 200px;
                    height: 100px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 8px;
                    &:hover {
                        background-color: black;
                        opacity: 0.8;
                    }
                    .btn {
                        background-color: white;
                        color: #d60000;
                        font-weight: 600;
                    }
                }
                .title {
                    font-size: 1rem;
                    margin-bottom: 0;
                    text-align: center;
                }
                .previewdoc {
                    width: 200px;
                    height: 100px;
                    object-fit: cover;
                    border-radius: 8px;
                    margin-bottom: 1em;
                }
            }
            
        }
        .address {
            display: flex;
            flex-wrap: wrap;
            .text {
                flex: 50%;
            }
            .map {
                flex: 50%;
                min-width: 200px;
                border-radius: 16px;
                overflow: hidden;
                .map-container {
                    padding: 0;
                }
            }
        }
    }
    
}

@media screen and (max-width: $breakpoint-tablet) {
    .user-details-main {
        .heading {
            position: sticky;
            top: 0.2em;
            z-index: 2;
        }
        .details-container {
            .status-to-right {
                position: relative;
                margin-bottom: 2em;
                .sticky-div {
                   position: relative;
                   padding: 0;
                    .status {
                        position: relative;
                        flex-wrap: wrap;
                        justify-content: center;
                        gap: 1em;
                    }
                }
            }
        }
    }
}