.states-list-main {
    .country-table-main {
        padding: 24px;
        background: #fff;
        border-radius: 16px 16px 0px 0px;
        margin-top: 34px;
        width: auto;

        .table-flex-main {
            display: flex;
            align-items: center;
            gap: 20px;
            margin-bottom: 50px;

            .status-select {
                background: #f1f3f4;
                padding: 10px;
                border-radius: 20px 20px;
                border: none;
                color: #ccbfdd;
            }

        }

        .country-table-head {
            margin-top: 40px;

            tr {
                th {
                    font-family: 'Montserrat';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    text-transform: capitalize;
                    color: #131313;
                }

            }

        }
    }

    .action-btn-main { 
        display: flex;
        padding-left: 1em;
        cursor: pointer;    
    }
}

.modal-btns {
    text-align: right;
    padding: 1em;
}