.supplier-order-table-main {
    padding: 24px;
    background: #fff;
    border-radius: 16px 16px 0px 0px;
    margin-top: 34px;
    //width: 100vw;

    .table-flex-main {
        display: flex;
        align-items: center;
        gap: 20px;
        margin-bottom: 50px;
    }

    .custom-table {
        thead {
            min-width: fit-content;
            tr {
                th {
                    font-family: 'Montserrat';
                    font-style: normal;
                    text-transform: capitalize;
                    white-space: nowrap;
                }
            }
        } 
        td {
            vertical-align: middle;
        }
    }
    .order-table-head {
        margin-top: 40px;

        tr {
            th {
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                text-transform: capitalize;
                color: #131313;
            }
        }

    }
    td {
        vertical-align: middle;
    }

    .suppplier-order-status-main {
        padding: 4px 8px;
        background: #F2AA1F;
        border-radius: 20px;
        text-align: center;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        text-transform: capitalize;
        color: #FFFFFF;
    }
}