.quotation-overview-main {
  font-size: 14px;
  .invoice-generator-main.row {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .topbar {
    .box-squar {
      width: 3rem;
      height: 3rem;
      border: 1px solid #eae5ff;
      border-radius: 10px;
      font-size: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .box-rect {
      width: 12rem;
      height: 3rem;
      border: 1px solid #eae5ff;
      display: flex;
      align-items: center;
      justify-content: space-around;
      border-radius: 10px;
    }
    .pointer {
      cursor: pointer;
    }
  }
  .container {
    hr {
      border: 1px dashed #71717a;
    }
    .sign-box {
      width: 12rem;
      height: 8rem;
      border: 1px solid black;
      border-radius: 10px;
    }
    .fs-12 {
      font-size: 12px;
    }

    .border-top-bottom {
      border-top: 1px solid #71717a;
      border-bottom: 1px solid #71717a;
    }
  }
}
.share-dropdown {
  width: 12rem;
  height: 3rem;
  border: 1px solid #eae5ff !important;
  background-color: #fff !important;
  border-radius: 10px;
  justify-content: space-around;
  span {
    margin-left: 10px;
    color: #212529 !important;
  }
}
