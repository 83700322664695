@import "../../assets//scss/variables.scss";

.delete-popup-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  .inner-container-text {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    text-transform: capitalize;
    color: #131313;
    text-align: center;
    line-height: 30px;
  }

  .action-btn {
    display: flex;
    justify-content: center;
    gap: 20px;
    .action-buttons {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      border-radius: 8px;
      border: none;
      background-color: none;
      padding: 10px 40px;
    }
    .delete-btn{
        background-color: #D60000;
        color: #FFFFFF;
    }
  }
}
