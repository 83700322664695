@import "../../assets/scss/variables.scss";
@import "../../assets/scss/mixins.scss";

.header-main {
  display: flex;
  justify-content: flex-end;
  padding: 1rem 1em 1rem 0.5em;
  background-color: #ffffff;

  .header-menu {
    display: flex;
    gap: 10px;
    .status {
      display: flex;
      align-items: center;
      margin: 0;
      p {
        margin: 0;
        color: $primaryColor;
        font-weight: 500;
        line-height: 2;
        span {
          padding: 0.3em 0.5em;
          border-radius: 8px;
          font-weight: 600;
        }
        @include status-styling;
      }
    }
    .header-select {
      border: 1px solid #f1f3f4;
      outline: none;
      background: none;
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      color: #413d3d;
      option {
        text-align: center;
      }
    }
  }

  .icon-dropdown:hover {
    cursor: pointer;
    .user-dropdown {
      color: #d60000;
    }
  }
  .options-lists {
    .item {
      padding-left: 5px;
      padding-right: 5px;
    }
  }
  .notification-list {
    list-style: none;
    padding-left: 0px;
    margin: 5px 0px;
    .notification-list-main {
      display: flex;
      align-items: center;
      margin-bottom: 5px;
      padding: 5px;
    }
    .notification-list-main {
      &:hover {
        background-color: #f1f3f4;
        border: 1px solid #56E20A;
        border-radius: 5px;
        width: 100%;
      }
    }
    img {
      width: 35px;
      height: 35px;
    }
    .notification-details {
      margin: 2px 5px;
      .message {
        margin-bottom: 0;
        padding: 0px;
        font-size: 12px;
        font-family: "Montserrat";
        font-style: bold;
        font-weight: 500;
      }
      .time {
        margin-bottom: 0;
        font-size: 12px;
        color: #413d3d;
      }
    }
  }
  .user-dropdown {
    width: 48px;
    height: 48px;
    background: #ffffff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ccbfdd;
    pointer-events: none;
    position: relative;
     img{
      width: 48px;
    }
    .notifications-badge {
      position: absolute;
      margin-top: -1.5em;
      margin-right: -1.5em;
      font-size: 0.7rem;
      background-color: $primaryColor !important;
    }
  }
 
  .icon-dropdown{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 210px;
    background-color: #EDF0F7;
    padding: 4px 12px;
    border-radius: 10px;
  }
  .email-name{
    line-height: 0.1;
    p{
      color: #000;
      font-size: 14px;
      font-weight: 600;
      font-family:Montserrat;    
    }
    span{
      font-size: 12px;
      color: #A7A8AB;
      width: 104px;
    }
  }
  .down-arrow{
    // padding: 10px;
  }
  .user-dropdown-menu {
    box-shadow: 1px 1px 4px gray;
    border: 0;
    padding: 0;
    border-radius: 1px;
    .menupointer {
      position: absolute;
      right: 1.2em;
      top: -0.3em;
      width: 10px;
      height: 10px;
      transform: rotate(45deg);
      background-color: #ffffff;
      box-shadow: -1px -1px 2px lightgray;
      z-index: -1;
    }
    .options-list {
      font-family: "Montserrat";
      display: flex;
      flex-direction: column;
      padding: 10px 0 0;
      margin: 0;
      z-index: 1;
      .item {
        padding: 0;
        p {
          width: 100%;
          height: 100%;
          padding: 8px;
          margin: 0;
          font-weight: 500;
          text-align: center;
        }
      }
      .item:hover {
        background-color: #56E20A;
        color: #ffffff;
      }
    }
  }

  .notification {
    display: inline-block;
    .dropdown-menu {
      padding: 0rem 0 !important;
    }
  }
  button.notification-button {
    color: #000000;
    width: 52px;
    height: 55px;
    background-color:#EDF0F7;
    border-color: #EDF0F7;
    img{
      height: 30px;
    }
  }

  .notification-button:hover,
  .notification-button:active,
  .notification-button:focus,
  .btn-secondary:not(:disabled):not(.disabled).active,
  .btn-secondary:not(:disabled):not(.disabled):active,
  .show > .btn-secondary.dropdown-toggle {
    background-color: rgba(99, 115, 129, 0.08);
    color: #333;
    background-color: transparent;
    border-color: transparent;
    box-shadow: none;
  }
  .dropdown-menu.show {
    border-radius: 8px;
    padding: 0rem 0 !important;
  }
  .notification-count {
    display: flex;
    flex-flow: row wrap;
    place-content: center;
    align-items: center;
    position: absolute;
    box-sizing: border-box;
    font-family: "Public Sans", sans-serif;
    font-weight: 600;
    font-size: 0.75rem;
    min-width: 20px;
    line-height: 1;
    padding: 0px 6px;
    height: 20px;
    border-radius: 10px;
    z-index: 1;
    transition: transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: #56E20A;
    color: rgb(0, 0, 0);
    top: 10px;
    right: 10px;
    transform: scale(1) translate(50%, -50%);
    transform-origin: 100% 0%;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #dfe6ed;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #dfe6ed;
  }
  .notification-main-seciton {
    background-color: rgb(255, 255, 255);
    color: rgb(33, 43, 54);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 8px;
    background-image: none;
    outline: 0px;
    overflow: inherit;
    // border: 1px solid rgba(145, 158, 171, 0.08);
    width: 360px;

    height: 522px;
    overflow-x: hidden;
  }
  .notification-header {
    display: flex;
    align-items: center;
    padding: 16px 20px;
    background-color: #56E20A;
    
  }
  .header-text {
    margin: 0px;
    font-weight: 600;
    line-height: 1.5;
    font-size: 1rem;
    font-family: "Public Sans", sans-serif;
    color: #000;
  }
  .normal-text {
    margin: 0px;
    line-height: 1.57143;
    font-size: 0.875rem;
    font-family: "Public Sans", sans-serif;
    font-weight: 400;
    color: rgb(99, 115, 129);
  }
  .normal-text-header {
    margin: 0px;
    line-height: 1.57143;
    font-size: 0.875rem;
    font-family: "Public Sans", sans-serif;
    font-weight: 400;
    color: #424242;
  }
  .left-section {
    flex-grow: 1;
  }
  .right-section {
    .loader-on-save .ball-clip-rotate-multiple > div {
      border: 2px solid #fff;
      border-bottom-color: transparent;
      border-top-color: transparent;
    }
    .loader-on-save .ball-clip-rotate-multiple > div:last-child {
      border-color: #fff;
    }
  }

  .check-double-fill-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    background-color: transparent;
    outline: 0px;
    border: 0px;
    margin: 0px;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    text-align: center;
    flex: 0 0 auto;
    font-size: 1.5rem;
    padding: 8px;
    border-radius: 50%;
    overflow: visible;
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    color: #fff;
  }
  .check-double-fill-button:hover {
    background-color: #fff;
    color: #ec4646;
  }
  .divider {
    margin: 0px;
    flex-shrink: 0;
    border-width: 0px 0px thin;
    border-style: solid;
    border-color: rgba(145, 158, 171, 0.24);
  }

  .notification-list {
    list-style: none;
    margin: 0px;
    padding: 0px;
    position: relative;
    .loading-data {
      display: flex;
      justify-content: center;
      margin-top: 100px;
      margin-bottom: 100px;
    }
  }
  .list-sub-header {
    box-sizing: border-box;
    list-style: none;
    // color: rgb(99, 115, 129);
    color: #ffffff;
    padding: 8px 20px;
    font-weight: 700;
    line-height: 1.5;
    font-size: 0.75rem;
    letter-spacing: 1.1px;
    text-transform: uppercase;
    font-family: "Public Sans", sans-serif;
    background-color: rgb(0, 0, 0);
  }
  .notification-list-li {
    -webkit-tap-highlight-color: transparent;
    outline: 0px;
    border: 0px;
    margin: 1px 0px 0px;
    border-radius: 0px;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    color: inherit;
    display: flex;
    flex-grow: 1;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    text-decoration: none;
    box-sizing: border-box;
    text-align: left;
    padding: 12px 20px;
  }
  .notification-list-li:hover {
    background-color: rgba(145, 158, 171, 0.16);
  }
  .notification-list-li-open {
    -webkit-tap-highlight-color: transparent;
    outline: 0px;
    border: 0px;
    margin: 1px 0px 0px;
    border-radius: 0px;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    color: inherit;
    display: flex;
    flex-grow: 1;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    text-decoration: none;
    box-sizing: border-box;
    text-align: left;
    padding: 12px 20px;
    background-color: rgba(145, 158, 171, 0.16);
  }
  .notification-list-li-open:hover {
    background-color: #98fea7db;
    color: #000000;
    .normal-text {
      margin: 0px;
      line-height: 1.57143;
      font-size: 0.875rem;
      font-family: "Public Sans", sans-serif;
      font-weight: 400;
      color: #424242;
    }
    .list-item-timeline {
      margin: 4px 0px 0px;
      line-height: 1.5;
      font-size: 0.75rem;
      font-family: "Public Sans", sans-serif;
      font-weight: 400;
      color: #424242;
      display: flex;
      align-items: center;
    }
  }

  .notification-list-item {
    flex: 1 1 auto;
    min-width: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .list-item-h6 {
    margin: 0px;
    font-weight: 600;
    line-height: 1.57143;
    font-size: 0.875rem;
    font-family: "Public Sans", sans-serif;
  }

  .list-item-timeline {
    margin: 4px 0px 0px;
    line-height: 1.5;
    font-size: 0.75rem;
    font-family: "Public Sans", sans-serif;
    font-weight: 400;
    color: rgb(145, 158, 171);
    display: flex;
    align-items: center;
  }
  .image-icon {
    height: 40px;
    width: 40px;
    display: inline-block;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      border-radius: 50%;
      position: relative;
      right: 10px;
      bottom: 5px;
    }
  }
}
