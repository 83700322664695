@import "../../assets/scss/variables.scss";

.table-inner-flex {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  align-items: center;
  .filter-grey-main {
    background:#EDF0F7;
    border-radius: 50px;
    padding: 12px 20px;
    display: flex;
    gap: 15px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #ccbfdd;
  }
  .status-select {
    background:#EDF0F7;
    padding: 10px;
    border-radius: 20px 20px;
    border: none;
    color: #ccbfdd;
  }
  .name-sort {
    cursor: pointer;
  }
  .sort-hightlight{
    color: $primaryColor;
  }
}

.table-search-width {
  width: $searchbar-max-width;
  // height: 20px;
  .table-search-main {
    padding: 2px !important;
    border-radius: 10px !important;
    background-color: #EDF0F7 !important;

    .table-search-icon,
    .table-search-input,
    .search-expand {
      background: #EDF0F7 !important;
      border: none;
    }
    .table-search-input{
      padding-left: 0;
    }
  }
}

@media only screen and (max-width: $breakpoint-mobile) {
    /* .product-main {
          .product-table-main {
            padding: 0;
            
            .table-flex-main {
              padding-block: 2em 1em;
              margin-bottom: 1em; */
    .table-inner-flex {
      width: unset;
      flex-wrap: nowrap;
      .filter-grey-main {
        .title {
          display: none;
        }
      }
    }

    .table-search-width {
      width: min-content;
      .table-search-main {
        padding: 0.3em;
        border-radius: 50px;
        width: min-content;
        flex-wrap: nowrap;
        .table-search-input {
          display: none;
        }
        .search-expand {
          width: 100px;
          border: none;
          background: none;
          /* position: absolute;
                    left: 3em;
                    top: 0; */
        }
      }
    }
}
/* .table-inner-flex {
    display: flex;
    gap: 20px;
    align-items: center;
    width: 85%;
    flex-wrap: wrap;
    .table-search-width {
        width: $searchbar-max-width;
    
        .table-search-main {
            padding: 7px;
            background: #F1F3F4;
            border-radius: 50px;
    
            .table-search-icon,
            .tale-search-input {
                background: none;
                border: none;
            }
        }
    }
    .filter-grey-main {
        background: #F1F3F4;
        border-radius: 50px;
        padding: 12px 20px;
        display: flex;
        gap: 15px;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: #CCBFDD;
    }
} */
