@mixin status-styling {
    .active, .approved {
        background-color: rgb(204, 245, 204);
        color: darkgreen;
    }
    .pending {
        background-color: rgb(244, 244, 181);
        color: rgb(124, 124, 2);
    }
    .rejected {
        background-color: rgb(242, 178, 178);
        color: darkred;
    }
    .blocked {
        background-color:bisque;
        color: red;
    }
    .inactive {
        background-color: black;
        color: white;
    }      
}