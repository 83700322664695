@import "../../assets/scss/variables.scss";

.order-main {
    overflow-x: scroll;

    .heading {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .heading-right-btn {
            display: flex;
            align-items: center;

            .select-role {
                margin-right: 30px;
                min-width: 15rem;

                input {
                    min-width: 100% !important;
                    background-color: #fff !important;
                }

                .css-1nmdiq5-menu {
                    z-index: 999;
                }
            }
        }
    }

    .content-main {
        overflow-x: scroll;
    }

    .tab-flex-main {
        display: flex;
        gap: 15px;
        margin-top: 25px;

    }

    .order-table-main {
        padding: 24px;
        background: #fff;
        border-radius: 16px 16px 0px 0px;
        margin-top: 34px;
        width: auto;

        td {
            min-height: 55px;
        }

        .table-flex-main {
            display: flex;
            align-items: center;
            gap: 20px;
            margin-bottom: 50px;

            .status-select {
                appearance: none;
                background: #f1f3f4;
                padding: 10px;
                border-radius: 20px 20px;
                border: none;
                color: #ccbfdd;
                background-image: url("../../assets/images/icons/dropdown-grey.svg");
                background-repeat: no-repeat;
                background-position-x: 90%;
                background-position-y: 50%;
                padding-right: 40px;
            }
        }

        .order-table-head {
            margin-top: 40px;

            tr {
                th {
                    font-family: 'Montserrat';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    text-transform: capitalize;
                    color: #131313;
                }

            }

        }
    }

    .avatar-image-main {
        border-radius: 50%;
        height: 40px;
        width: 40px;
        overflow: hidden;
        margin-top: -5px;

        img {
            width: 100%;
            height: 100%;
        }
    }
}

.custom-table-with-border{
    border-bottom: 1px solid black;
}