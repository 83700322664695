@import "../../assets/scss/variables.scss";

.product-details-main {
  .product-details-primary-heading {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    color: #000000;
  }

  .product-details-headings-main {
    display: flex;
    justify-content: space-between;
  }

  .change-status-flex-main {
    display: flex;
    align-items: center;
    gap: 25px;
    p {
      margin: 0;
    }
  }

  .product-details-card {
    background: #ffffff;
    border-radius: 16px;
    padding: 30px;
    font-family: "Montserrat";
    font-style: normal;
    margin: 30px 0px;

    .product-details-card-heading {
      font-weight: 600;
      font-size: 24px;
      color: #333333;
    }

    .product-details-flex-main {
      display: flex;
      gap: 30px;
      font-family: "Montserrat";
      font-style: normal;
      align-items: stretch;
      img {
        border-radius: 8px;
      }

      .product-details-left-main {
        width: 50%;
        .swiper_container {
          .swiper {
            width: 100%;
            height: 100%;
          }

          .swiper-slide {
            text-align: center;
            font-size: 18px;
            background: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .swiper {
            width: 100%;
            height: 300px;
            margin-left: auto;
            margin-right: auto;
          }

          .swiper-slide {
            background-size: cover;
            background-position: center;
          }

          .mySwiper2 {
            height: 80%;
            width: 100%;
          }

          .mySwiper {
            height: 20%;
            box-sizing: border-box;
            padding: 10px 0;
          }

          .mySwiper .swiper-slide img {
            width: 100%;
            height: 150px;
            object-fit: cover;
          }

          .mySwiper .swiper-slide {
            width: 25%;
            height: 100%;
            opacity: 0.4;
          }

          .mySwiper .swiper-slide:hover {
            cursor: pointer;
          }

          .mySwiper .swiper-slide-thumb-active {
            opacity: 1;
          }

          .product-desc-image-main {
            height: 600px;
            display: block;
            width: 100%;
            object-fit: contain;
            object-position: center;
          }
        }
        .reorder-click {
          font-weight: 600;
          color: $primaryColor;
          cursor: pointer;
        }
      }

      .product-details-right-main {
        width: 50%;
      }
    }

    .product-details-secondary-heading-main {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .product-details-stock-count {
        font-family: "Inter";
        font-size: 14px;
        color: #31a727;
        font-weight: 500;
      }
    }

    .product-details-secondary-heading {
      font-weight: 500;
      font-size: 20px;
      text-transform: capitalize;
      color: #131313;
    }

    .product-details-text {
      font-weight: 400;
      font-size: 16px;
      text-transform: capitalize;
      color: #413d3d;
    }

    .solid-border-line {
      margin: 30px 0px;
      border-bottom: 1px solid #000;
    }

    .product-details-bottom-flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 0px;
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      text-transform: capitalize;
      color: #131313;
    }

    .font-size-24 {
      font-size: 24px;
    }

    .product-details-right-edit-main {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 70px;
      gap: 1em;
      .product-details-right-edit-btn {
        background: #2f49d1;
        border-radius: 5px;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        color: #ffffff;
        border: none;
        padding: 8px 50px;
      }
    }
  }
}

@media only screen and (max-width: $breakpoint-tablet) {
  .product-details-main .product-details-card {
    width: 100%;
  }

  .product-details-main .product-details-card .product-details-bottom-flex {
    font-size: 16px;
  }

  .product-details-card {
    .product-details-flex-main {
      flex-direction: column;
      .product-details-left-main,
      .product-details-right-main {
        width: 100% !important;
      }
    }
  }
}
