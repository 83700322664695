.chat-main {
  .chat-box {
    position: relative;
    min-height: 500px;
    max-height: 500px;
    overflow-y: auto;
    padding: 10px 30px 20px 30px;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 8px;
  }

  .chat-box .chat {
    margin: 15px 0;
  }

  .chat-box .chat p {
    word-wrap: break-word;
    padding: 8px 16px;
    box-shadow: 0 2px 0 rgba(90, 97, 105, 0.11),
      0 4px 8px rgba(90, 97, 105, 0.12), 0 10px 10px rgba(90, 97, 105, 0.06),
      0 7px 70px rgba(90, 97, 105, 0.1);
  }

  .chat-box .outgoing {
    display: flex;
  }

  .chat-box .outgoing .details {
    margin-left: auto;
    max-width: calc(100% - 130px);
    margin-right: 9px;
  }

  .outgoing .details p {
    background: #687eff;
    color: #fff;
    border-radius: 18px 18px 0 18px;
  }

  .datetime-outgoing {
    display: block;
    font-size: 12px;
    text-align: right;
  }

  .chat-box .incoming {
    display: flex;
    align-items: flex-end;
  }

  .chat-box .incoming .details {
    margin-right: auto;
    margin-left: 10px;
    max-width: calc(100% - 130px);
  }

  .incoming .details p {
    background: #333;
    color: #fff;
    border-radius: 18px 18px 18px 0;
    padding: 10px;
  }

  .datetime {
    display: block;
    font-size: 12px;
    text-align: left;
  }

  .no-communication {
    text-align: center;
    padding-top: 40px;
    font-size: 24px;
    font-weight: 600;
  }

  .msg-bottom {
    border-radius: 15px;
    display: block;
    height: 60px;
    font-size: 1rem;
    line-height: 1.5;
    background-clip: padding-box;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    border: 0.5px solid #56e20a !important;
    .input-group {
      padding-left: 10px;
      width: 100% !important;
      height: 50px;
      display: flex;

      .text-area {
        width: 91%;

        .form-control {
          border: none !important;
          border-radius: 15px !important;
          display: block;
          height: 56px;
          padding: 0.375rem 0.75rem;
          font-size: 1rem;
          line-height: 2.5;
          background-clip: padding-box;
          transition: border-color 0.15s ease-in-out,
            box-shadow 0.15s ease-in-out;
        }
      }
      .send-btn {
        height: 40px;
        width: 47px;
        margin: 8px 5px;
        background-color: #56e20a!important;
        border: none;
        border-radius: 10px;
        .tele-icon {
          height: 30px;
          width: 35px;
          padding-right: 11px;
        }
      }
    }
  }
}
