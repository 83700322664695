@import "../../assets/scss/variables.scss";
@import "../../assets/scss/mixins.scss";
//voucher list page
.voucher-main {
   
    .voucher-table-main {
        padding: 24px;
        background: #fff;
        border-radius: 16px 16px 0px 0px;
        margin-top: 34px;

        .table-flex-main {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 50px;
            .add-voucher-btn {
                background: #2F49D1;
                border-radius: 8px;
                padding: 10px 16px;
                color: #fff;
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                cursor: pointer;
            }
            .status-select{ 
                appearance: none;
                background-image: url("../../assets/images/icons/dropdown-grey.svg");
                background-repeat: no-repeat;
                background-position-x: 90%;
                background-position-y: 50%;
                padding-right: 40px;
            }
        }

        .voucher-table-head {
            margin-top: 40px;

            tr {
                th {
                    font-family: 'Montserrat';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    text-transform: capitalize;
                    color: #131313;
                }

                .width-140 {
                    width: 140px;
                }
            }

        }

        tbody {
            tr {
                .action-btn-main {
                    display: flex;
                    gap: 15px;
                }
            }
        }
    }
}
@media only screen and (min-width: 786px) and (max-width: 999px){
    .voucher-main .voucher-table-main{
        width: 100vw;
    }
    .voucher-main .voucher-table-main .table-flex-main .table-inner-flex {
        width: 100%;
    }
    .voucher-main .voucher-table-main .table-flex-main{
        display: block;
    }
    .add-voucher-btn-margin{
        margin-top: 25px;
    }
    .voucher-main .voucher-table-main .table-flex-main{
        margin-bottom: 30px;
    }
}