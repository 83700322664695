.order-details-main {
    .order-details-heading-main {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        color: #000000;
    }

    .order-details-right-main {
        background: #FFFFFF;
        border-radius: 16px;
        padding: 30px;
        width: 65%;

        .order-id-flex-main {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-family: 'Montserrat';
            font-style: normal;
            padding-bottom: 30px;

            .order-id-black-text {
                font-weight: 600;
                font-size: 24px;
                color: #333333;
            }

            .order-id-green-text {
                font-weight: 500;
                font-size: 14px;
                color: #4F9807;
            }
        }

        .Billing-address-main {
            font-family: 'Montserrat';
            font-style: normal;
            display: flex;
            gap: 10px;

            .order-icon-bg {
                width: 40px;
                height: 40px;
                background: rgba(254, 88, 88, 0.25);
                border-radius: 50px;
                display: flex;
                align-items: center;
                justify-content: center;

                .order-icon {
                    width: 25px;
                    height: 25px;
                    color: #D60000;
                }
            }

            .billing-address-heading {
                font-weight: 600;
                font-size: 14px;
                color: #000000;
            }

            .billing-address-text {
                font-weight: 400;
                font-size: 12px;
                color: #413D3D;
            }
        }

        .dotted-line-main {
            border-bottom: 1px dashed #535655;
            width: 80%;
            margin: auto;
        }

        .item-card-main {
            font-family: 'Montserrat';
            font-style: normal;

            .item-card-heading {
                font-weight: 600;
                font-size: 14px;
                color: #CCBFDD;
                margin: 30px 0px 20px;
            }

            .table-image-main {
                width: 25px;
                height: 25px;
                border-radius: 50%;

                .table-image {
                    width: 100%;
                    height: 100%;
                }
            }

            .item-card-sub-heading {
                font-weight: 500;
                font-size: 14px;
                color: #131313;
            }

            .item-card-flex {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 10px;

                .item-card-inner-flex {
                    display: flex;
                    gap: 20px;
                    align-items: center;
                }

                .item-total-flex {
                    font-family: 'Montserrat';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    color: #535665;
                }
            }
        }

        .total-bill-flex {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .total-bill-inner-flex {
                display: flex;
                gap: 20px;
            }
        }

        .total-bill-text-bold {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            color: #333333;
        }

        .total-bill-text-fent {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            color: #535665;
        }
    }

    .order-details-left-main {
        width: 35%;

        .order-details-inner-main {
            background: #FFFFFF;
            border-radius: 16px;
            padding: 30px;
            margin-bottom: 30px;

            .order-details-heading-main {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 600;
                font-size: 24px;
                color: #000000;
            }

            .order-form-group {
                width: 100%;
            }

            .order-form-group-label {
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                color: #413D3D;
                margin-bottom: 10px;
            }

            .order-left-btn-flex {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .red-trash-btn {
                    font-family: 'Montserrat';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    color: #D60000;
                    background: none;
                    border: none;
                }

                .update-btn {
                    background: #2F49D1;
                    border-radius: 5px;
                    font-family: 'Montserrat';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    color: #FFFFFF;
                    border: 1px solid #2F49D1;
                }

                .order-left-btn-text {
                    margin: 0;
                    font-family: 'Montserrat';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    color: #413D3D;
                }

                .order-left-btn-bold-text {
                    margin: 0;
                    font-family: 'Montserrat';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    color: #000;
                }
            }

            .order-grey-card {
                background: #E8E8E8;
                border-radius: 16px;
                padding: 16px;
                margin: 20px 0px;

                .order-grey-card-text {
                    font-family: 'Montserrat';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    margin: 0;
                    color: #413D3D;
                }
            }

            .solid-line-main {
                border-bottom: 1px solid #000;
                padding-top: 20px;
            }

            .private-select-flex-main {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .private-select-form-group {
                    border: none;
                    font-family: 'Montserrat';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    color: #CCBFDD;
                }

                .add-btn {
                    background: #2F49D1;
                    border-radius: 5px;
                    font-family: 'Montserrat';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    color: #FFFFFF;
                    border: 1px solid #2F49D1;
                    text-transform: uppercase;
                }
            }
        }
    }
}