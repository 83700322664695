@import "../../assets/scss/variables.scss";
@import "../../assets/scss/mixins.scss";

.users-main {
  display: relative;
  .tab-flex-main {
    display: flex;
    gap: 15px;
    margin-top: 25px;
  }
  .status-select {
    background: #f1f3f4;
    padding: 10px;
    border-radius: 20px 20px;
    border: none;
    color: #ccbfdd;
  }

  .user-table-main {
    padding: 24px;
    border-radius: 16px 16px 0px 0px;
    margin-top: 34px;
    width: auto;
    background: #fff;

    .table-flex-main {
      display: flex;
      align-items: center;
      margin-bottom: 50px;
      justify-content: space-between;
    }
    .table {
      .username {
        color: black;
        font-weight: 500;
        &:hover {
          cursor: pointer;
        }
      }
      .status {
        width: 100px;
        p {
          padding: 0.1em 0.2em;
          text-align: center;
          font-weight: 500;
          border-radius: 16px;
        }
        @include status-styling;
      }
    }
    .voucher-table-head {
      margin-top: 40px;

      tr {
        th {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          text-transform: capitalize;
          color: #131313;
        }

        .width-140 {
          width: 140px;
        }
      }
    }

    tbody {
      tr {
        .action-btn-main {
          display: flex;
          gap: 15px;
        }
      }
    }
  }

  .table-text-main {
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    font-family: "Montserrat";
    font-style: normal;
    margin-top: 50px;

    p {
      margin: 0;
    }
  }
}

@media only screen and (max-width: $breakpoint-mobile) {
  .users-main {
    .tab-flex-main {
      overflow-x: scroll;
      flex-direction: column;
    }
  }
}
