@import "../../assets/scss/variables.scss";
//Primary Button

.save-btn-div {
    .save-btn {
        min-width: 150px;
        color: #fff;
        display: inline-block;
        background: #56E20A;
        border-radius: 5px;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 1rem;
        padding: 0.7em 1em;
        cursor: pointer;
       
        border: none;
        margin-left: 10px;
    }
    // .save-btn:hover {
    //     background: #D60000;
    // }
}

//Icon Button
.btn-with-icon {
    background: #56E20A!important;
    border-radius: 8px;
    padding: 0.72em 1em !important;
    color: #ffffff;
    font-family: 'Montserrat' !important;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    
    cursor: pointer;
    border: none !important;
    display: flex !important;
    border-radius: 8px !important;
    align-items: center;
    justify-content: center;
    .btn-with-icon-title {
        margin: 0 0 0 5px !important;
    }
    .btn-icon, img {
        font-size: 30px;
        margin-inline: 5px;
       
    }
}

//table image css
.table-img-div {
    width: 48px;
    height: 48x;
    margin: auto;
    border-radius: 50%;

    .table-image {
        width: 100%;
        height: 100%;
    }
}

//tabs css
.tabs-main {
    padding: 10px 30px;
    background: #FFFFFF;
    border-radius: 16px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    color: #000000;
    cursor: pointer;
}

.tabs-main:hover {
    background: #56E20A;
    color: #fff;
}

.tabs-main.active {
    background: #fff;
    color: black;
}

.tabs-main.active:hover {
    background-color: #56E20A;
}

//Edit action css
.edit-icon-main {
    padding: 1px 4px;
    width: 30px;
    height: 30px;
    background: rgba(0, 232, 9, 0.15);
    border-radius: 50px;
    cursor: pointer;

    .edit-icon {
        width: 20px;
        height: 20px;
        color: #00E809;
    }
}

//Delete action css
.delete-icon-main {
    padding: 1px 4px;
    width: 30px;
    height: 30px;
    border-radius: 50px;
    background: rgba(254, 158, 88, 0.15);
    cursor: pointer;

    .delete-icon {
        width: 20px;
        height: 20px;
        color: #fe9858;
    }
}

@media screen and (max-width: $breakpoint-mobile) {
    .btn-with-icon {
        .title {
            display: none;
        }
    }
}