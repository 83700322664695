.restaurant-each-product {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px 12px 14px;
  gap: 8px;
  isolation: isolate;
  background: #ffffff;
  border-radius: 8px;
  position: relative;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin: 5px;
  cursor: pointer;
  .product-img {
    width: 100%;
    height: 400px;

    img {
      width: 100%;
      object-fit: cover;
      height: 100%;
      border-radius: 8px;
    }
  }

  .action-list-icon {
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
    padding: 1em;
    width: 40px;
    height: 40px;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
  }

  .actions-list {
    padding: 0.5em 0.7em;
    display: flex;
    flex-direction: column;
    gap: 0.3em;
    position: absolute;
    width: auto;
    height: auto;
    right: 46px;
    top: 26px;
    background: #ffffff;
    box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.2);
    border-radius: 8px;

    .actions-hr {
      margin: 2px;
    }

    .action-icon {
      color: #fe5858;
      margin-right: 5px;
    }
    .action {
      font-family: "Montserrat";
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 0.4em;
      cursor: pointer;
    }
    .action-edit {
      margin-bottom: 10px;
      cursor: pointer;
    }

    .action-delete {
      margin-bottom: 10px;
      cursor: pointer;
    }
  }

  .name-and-actions {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .product-name {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      text-transform: capitalize;
      color: #000000;
      cursor: pointer;
    }

    .instock-main {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      text-transform: capitalize;
      color: #31a727;
    }
  }

  .product-description {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    text-transform: capitalize;
    color: #000000;
    margin-bottom: 0;
    p {
      height: 2.5em;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .product-details {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .category-type {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      text-transform: capitalize;
      color: #000000;
    }

    .category-details {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      text-align: right;
      text-transform: capitalize;
      color: #000000;
    }

    p {
      margin-bottom: 0;
    }
  }
}
