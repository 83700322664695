//add supplier css
@import "../../assets/scss/variables.scss";

.supplier-main {
    .supplier-topcard-main {
        padding: 40px 24px;
        background: #FFFFFF;
        border-radius: 16px;
        margin-top: 60px;

        .supplier-heading {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            color: #131313;
        }

        .user-form-group {
            border: 1px solid #F1F3F4;
            background: #F1F3F4;
            border-radius: 8px;

        }
    }

    .class-suppliercard-flex {
        display: flex;
        gap: 30px;

        .supplier-bottomcard-main {
            padding: 40px 24px;
            background: #FFFFFF;
            border-radius: 16px;
            width: 50%;
            margin: 30px 0px 50px;

            .supplier-heading {
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                color: #131313;
            }

            .user-form-group {
                border: 1px solid #F1F3F4;
                background: #F1F3F4;
                border-radius: 8px;

            }
        }
    }

    .address-main {
        .container {
            padding: 0;
            margin-top: 0;
        }
    }
    .save-btn-float {
        float: right;
        padding-bottom: 50px;
    }
}

@media only screen and (min-width: 786px) and (max-width: 999px) {
    .supplier-main .class-suppliercard-flex {
        display: block;
    }

    .supplier-main .class-suppliercard-flex .supplier-bottomcard-main {
        width: 100%;
    }
}

@media only screen and (max-width: $breakpoint-tablet) {
    .supplier-main .class-suppliercard-flex {
        display: block;
    }

    .supplier-main .class-suppliercard-flex .supplier-bottomcard-main {
        width: 100%;
    }
}