.view-party-main {
  .heading {
    font-size: 24px;
    font-weight: 700;
  }
  .box-squar {
    padding: 5px;
    border: 1px solid grey;
  }
  .view-party-actual {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
  }
  .view-party-side-heading {
    font-weight: 700;
    margin-bottom: 5px;
    height: 40px;
    padding: 5px 8px;
    background-color: #56e20a;

    display: flex;
    justify-content: space-between;
    align-items: center;
    .message-icon{
      background-color: rgba(255, 255, 255, 0.5); 
      filter: blur(0.5px); 
      border-radius: 10px;
      padding: 8px;
   
    }
  }
  .view-party-each-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 5px;
  }
}
.billing-shipping {
  display: flex;
  justify-content: space-between;
  width: 100%;
  .billing-add {
    width: 48%;
    border: 1px solid #ccc;
    padding: 5px;
  }
  .shipping-add {
    width: 48%;
    border: 1px solid #ccc;
    padding: 5px;
  }
}

.custom-table {
  width: 500px; /* Set the width of the table */
  border-collapse: collapse; /* Collapse borders into a single border */
}

.custom-table th,
.custom-table td {
  border: 1px solid rgb(219, 217, 217); /* Add border to cells */
  padding: 8px; /* Add padding to cells */
  text-align: left; /* Align text to the left */
}

.custom-table th {
  background-color: #f2f2f2; /* Background color for header cells */
}

.second-table {
  margin-top: 20px;
  background: #fff;
  padding: 20px;
  border-radius: 20px;
}
