.balloons-pagination {
  width: 100%;
  margin-top: 20px;
  .rows-per-page{
    width: 50%;

  }
  .pagination-main {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    .page-item .page-link {
      color: #000;
    }
    .page-item.active > .page-link,
    .page-link.active {
      z-index: 0;
      color: #fff;
      background-color: 
      #56E20A;
      border-color: 
      #56E20A;
    }
    .disabled > .page-link,
    .page-link.disabled {
      color: var(--bs-pagination-disabled-color);
      pointer-events: none;
      background-color: var(--bs-pagination-disabled-bg);
      border-color: var(--bs-pagination-disabled-border-color);
    }
    .page-link:hover {
      background-color: #d60000;
      color: #fff;
    }
    .page-link:focus {
      box-shadow: none;
      background-color: #fff;
      color: #000;
    }
    .disabled .page-link {
      box-shadow: none;
      background-color: #eeeeee66;
      color: #000;
    }
  }
}
