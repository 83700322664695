.order-invoice-main {
  .download-icon-main {
    padding: 10px 16px;
    border-radius: 8px;
    background: white;
    display: inline-block;
    cursor: pointer;
  }
  .order-invoice-card-main {
    background: #fff;
    border-radius: 5px;
    padding: 24px;
  }
  .order-invoice-header-main {
    font-size: 3rem;
    font-weight: 600;
    font-family: "Montserrat";
    font-style: normal;
    color: #000;
  }
  .order-invoice-logo-main {
    height: 100px;
    margin-right: 40px;
    img {
      height: 100%;
    }
  }
  .width-half {
    max-width: 50%;
  }
  .order-invoice-subheader-main {
    font-size: 1.5rem;
    font-weight: 500;
    font-style: normal;
    color: #000;
    margin-bottom: 12px;
    text-transform: capitalize;
  }
  .avatar-image-main {
    border-radius: 50%;
    height: 40px;
    width: 40px;
    overflow: hidden;
    margin-top: -5px;

    img {
      width: 100%;
      height: 100%;
    }
  }
}
