@import "../../assets/scss/variables";

.sidebar-main {
  padding: 20px;
  padding-bottom: 40px;
  // background: #ffffff;
  box-shadow: 1px 1px 4px #ececec;
  height: 100vh;
  position: relative;
  .simplebar-scrollbar::before {
    background-color: #00a434;
    opacity: 0.7 !important;
  }

  .sidebar-logo {
    // position: sticky;
    // background: #115CC9;
    top: 0;
    // z-index: 0;
    text-align: center;
    img{
      width: 40%;
    }
    .sidebar-logo-actual {
      color: white;
      font-size: 32px;
      font-weight: 700;
    }
  }
  hr{
    width: 100%;
    background-color: #ffffff;
    height: 1.5px;
  }

  .sidebar-menu-list {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    justify-content: center;
  //  align-items: center;
    /* .each-icon-div{
    } */
    .each-icon {
      // background: #f1f3f4;
      // border-radius: 5px;
      // padding: 0.75rem;
      // color: #ccbfdd;
      // font-size: 30px;
      //  display: flex;
      width: 220px;
      padding: 5px;
      text-decoration: none;
      color:white;
      img {
        object-fit: contain;
        width: 30px;
        height: 30px;
      }

      /* .icon {
      } */
    }

    .each-icon:hover {
      // background: white;
      color: green !important;
      border-radius: 5px;


      img {
        filter: invert(9%) sepia(92%) saturate(7479%) hue-rotate(7deg) brightness(89%) contrast(112%);
      }
    }

    .each-icon.active {
     
      color: white !important;
      border-radius: 5px;


      img {
        filter: invert(9%) sepia(71%) saturate(7469%) hue-rotate(0deg) brightness(227%) contrast(12%);
      }
    }
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .sidebar-main {
    display: flex;
    flex-direction: column;
    height: auto;
    padding: 1em;

    .sidebar-logo {
      display: none;
    }

    .sidebar-menu-list {
      margin: 0;
      padding-right: 1em;
      display: flex;
      flex-direction: row;
      height: fit-content;
      gap: 12px;
      justify-content: space-evenly;
      align-items: center;
      width: fit-content;

      .each-icon-div {
        height: auto;
        margin: 0;

        .each-icon {
          padding: 0.5em;
          vertical-align: middle;
          /* .icon {
            font-size: 2rem;
          } */
        }
      }
    }
  }
}

.radio-label.active{
  color: #F59C1E;
}

.Active{
  color: #00a434;
}


.each-icon:hover .icon {
  color: #56E20A;
  
}

.each-icon:hover div {
  color: #56E20A;
}
.grid{
  width: auto;
height: 18px;

}
