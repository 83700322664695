.modal-body-custom {

}
.reorder-main{
    .content{
        display: flex;
        justify-content: center;
        .images-div {
            //display: flex;
            display: flex;
            flex-direction: column;
            gap: 1em;
            .each-div {
                display: flex;
                gap: 1em;
                align-items: center;
                
                border-radius: 8px;
                //overflow: hidden;
                p{
                    font-weight: 600;
                    font-family: 'Montserrat';
                }
                img {
                    object-fit: cover;
                    width: 150px;
                    height: 150px;
                    border-radius: 8px;
                }
            }
            
        }
    }
    .save-btn-container {
        display: flex;
        justify-content: center;
        margin-top: 30px;
  
        .save-btn {
          width: 300px;
        }
      }
}