@import "../../assets/scss/variables.scss";

.updateProductPrice-main {
    .update-btn-container {
        text-align: center;
        margin-block: 1em;
    }
    .errors-after-sumbit {
        color: $primaryColor;
        text-align: center;
        font-weight: 600;
        font-family: "Montserrat";
    }
}
.modal-header-custom {
    padding: 2rem 2rem !important;
    border: none !important;
}
.modal-body-custom {
    padding-top: 0 !important;
}
.updateProductPrice-main {
    margin: 0 40px 0 20px;
}
