@import "../../assets/scss/variables.scss";

.product-main {
  font-weight: 500;
  .uploaders {
    display: flex;
    .container {
      .file-uploader {
        text-align: center;
        font-family: "Montserrat";
        font-style: normal;
    
        .product-file-uploader {
          width: 250px;
          height: 250px;
          background: #c6b9d8;
          border-radius: 16px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 30px auto;
          background-repeat: no-repeat;
          background-size: 100% 100%;
          cursor: pointer;
    
          .camera-icon-bg {
            width: 48px;
            height: 48px;
            background: #ffffff;
            border-radius: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
    
            .camera-icon {
              color: #37007d;
              width: 30px;
              height: 30px;
            }
          }
        }
    
        .file-uploader-heading {
          font-weight: 600;
          font-size: 24px;
          color: #000000;
        }
    
        .file-uploader-text {
          font-weight: 400;
          font-size: 16px;
          color: #000000;
        }
      }
      .uploaded-images {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
        align-items: center;
        overflow: auto;
        gap: 1em;
        .image-box {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 0.5em;
          .view-image {
            object-fit: contain;
            width: 100px;
            height: 100px;
          }
        }
      }
    }
    .line {
      height: inherit;
      border-right: 1px solid #c6b9d8;
    }
  }
  

  .user-topcard-main {
    background: #ffffff;
    border-radius: 16px;
    padding: 40px 20px;
    margin-top: 40px;
    .short-description {
        .title {
            display: flex;
            justify-content: space-between;
            p{
                margin-bottom: 0;
                padding-bottom: 0;
            }
        } 
        .textarea {
            background-color: $primaryBGColor;
            border: none;
            width: 100%;
            border-radius: 8px;
            padding: 0.5em 1em;
            &:focus {
                outline: none;
                border: none;
            }
        }
    }
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .select {
      display: block;
      width: 100%;
      padding: 0.5em;
    }
    .topcard-heading,
    .dynamicfields-title {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
    }
    .color-selector-header {
      display: flex;
      align-items: center;
      gap: 1em;
      margin-block: 1em;
    }
    .color-selectors {
      gap: 1em;
      //border: 10px solid $primaryBGColor;
      margin: 1em 0;
      // width: 100%;
      overflow: visible;
      .color-box {
        background-color: $primaryBGColor;
        display: flex;
        align-items: center;
        border-radius: 8px;
        position: relative;
        margin-bottom: 10px;
        .color-name {
          border: none;
        }
        .color-selector {
          width: 1.5rem;
          height: 1.5rem;
          padding: 0;
          margin-inline: 1em;
          border-radius: 8px !important;
          border: 1px solid black;
          overflow: hidden;
          cursor: pointer;
        }
        .custom-picker {
          position: absolute;
          right: -1.5em;
          z-index: 1;
        }
        .close {
          text-align: center;
          color: $primaryColor;
          font-weight: 600;
          background-color: white;
          border: 1px solid $primaryColor;
          cursor: pointer;
        }
        .for-position {
          position: absolute;
          width: fit-content;
        }
        img {
          filter: invert(9%) sepia(92%) saturate(7479%) hue-rotate(7deg)
            brightness(89%) contrast(112%);
          cursor: pointer;
        }
      }

      Label {
        margin: 0;
        font-family: "Montserrat";
      }
    }
    .dynamicfields-title {
      margin-top: 2rem;
    }

    .product-form-group {
      background: #f1f3f4;
      border-radius: 8px;
      border: 1px solid #f1f3f4;
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      color: #413d3d;
    }
    /* .color-selector-group {
            display: flex;
            align-items: center;
            justify-content: center;
            background: $primaryBGColor;
            border-radius: 8px;
            padding-right: 0.7rem;
            .color-selector-div {
                flex: 1;
                height: 2.3rem;
            }
            #color-selector {
                width: 1.5rem;
                height: 1.5rem;
                padding: 0;
                margin: 0;
                border-radius: 8px !important;
                overflow: hidden;
            }
            
        } */
    .dynamicfield {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 1em;
      height: fit-content;
      margin: 0 1rem 1rem 0;
      .values-div {
        display: flex;
        flex-direction: column;
        gap: 0.7em;
        .delete-container {
          display: flex;
          gap: 0.5em;
          position: relative;
          .input {
            flex: 1;
          }
          .delete-icon {
            position: absolute;
            right: -1.5em;
            top: 0.7em;
          }
        }
      }
      .mb-3 {
        margin: 0 !important;
      }
      img {
        cursor: pointer;
        &:hover {
          path {
            fill: red;
          }
        }
      }
      .disabled {
        background-color: lightgrey !important;
      }
    }
    .dynamicTypefield {
      Label {
        text-transform: capitalize;
      }
    }
    .add-dynamic {
      display: flex;
      justify-content: flex-end;
    }
  }
  .topcard-description {
    padding: unset;
    .header {
      padding: 2.5em 1.25em 1.25em 1.25em;
    }
    .description-form-group {
      position: relative;
      height: 20rem;
      //overflow: hidden;
      .quill-custom {
        height: fit-content;
        .ql-toolbar {
          position: absolute;
          bottom: 0;
          left: 0.8em;
          right: 0.8em;
          margin-bottom: 0;
          padding: 1em 1.5em;
          display: flex;
          justify-content: space-between;
          border-radius: 0 0 16px 16px;
          border: none;
          box-shadow: 1px -0.1px 5px 1px rgba(0, 0, 0, 0.1);
        }
        .ql-container {
          height: 15rem;
          //padding: 0.2em 1em;
          margin-inline: 1.5em;
          margin-bottom: 1em;
          scrollbar-width: thin;
          border: none !important;
          .ql-editor {
            border-radius: 16px;
            background-color: #EDF0F7;
            scrollbar-width: thin;
          }
          .ql-editor::-webkit-scrollbar {
            width: 5px;
          }
          .ql-editor::-webkit-scrollbar-thumb {
            background: $primaryColor;
            border-radius: 16px;
          }
        }
      }
    }
  }

  .class-usercard-flex {
    display: flex;
    gap: 30px;

    .product-bottomcard-main {
      padding: 40px 24px;
      background: #ffffff;
      border-radius: 16px;
      width: 100%;//50%;
      margin-top: 30px;

      .user-heading {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        color: #131313;
      }

      .user-text {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        color: #413d3d;
      }

      .product-form-group {
        border: 1px solid #f1f3f4;
        background: #f1f3f4;
        border-radius: 8px;
      }
    }

    .add-product-btn {
      padding: 10px 16px;
      gap: 8px;
      background: #2f49d1;
      border-radius: 8px;
      display: inline-block;
      color: #fff;
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
    }
  }
  .errors-after-sumbit {
    color: $primaryColor;
    text-align: center;
    font-weight: 600;
    font-family: "Montserrat";
  }

}

/* .select-dropdown {
  border: none;
  .dropdown {
    width: 100%;
    margin-right: 0;
    .dropdown-trigger {
      border: none !important;
      width: 100%;
      border: none;
      border-radius: 8px;
      background-color: #f5f2ed;

      .search{
        border-bottom: none !important;
      }

      .tag{
        display: flex;
        background-color: #ffffff;
        border: none;
        border-radius: 8px;
        padding: 5px;
      }

      
    }
    .dropdown-trigger.arrow.bottom:after{
      content: url("../../assets/images/icons/dropdown-arrow.svg") !important;
    
    }
     .dropdown-trigger.arrow.top:after{
      content: url("../../assets/images/icons/dropdown-arrow.svg") !important;
    
    }
    .dropdown-trigger.arrow{
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px;
    }
  }
  .dropdown-content {
    width: 100%;

    .infinite-scroll-component {
      .node>label{
        width: 100%;
      }
    }

     
  }
} */

@media only screen and (max-width: $breakpoint-tablet) {
  .product-main {
    .uploaders {
      flex-direction: column;
    }
  }
}

@media only screen and (max-width: $breakpoint-tablet) {
  .product-main .class-usercard-flex {
    display: block;
  }
  .product-main .class-usercard-flex .product-bottomcard-main {
    width: 100%;
  }
  .product-main {
    .heading {
      position: sticky;
      top: 0.2em;
      z-index: 2;
    }
    .user-topcard-main {
      .color-selectors {
        .color-box {
          // width: 90%;
          .custom-picker {
            position: absolute;
            right: 14.5em;
            bottom: -1em;
          }
        }
      }
    }
    .topcard-description {
        padding: unset;
        .header {
            padding: 2.5em 1.25em 1.25em 1.25em ;
        }
        .description-form-group {
            position: relative;
            height: 20rem;
            //overflow: hidden;
            .quill-custom {
                height: fit-content;
                .ql-toolbar{
                    position: absolute;
                    bottom: 0;
                    left: 0.8em;
                    right: 0.8em;
                    margin-bottom: 0;
                    padding: 1em 1.5em;
                    display: flex;
                    justify-content: space-between;
                    border-radius: 0 0 16px 16px;
                    border: none;
                    box-shadow: 1px -0.1px 5px 1px rgba(0, 0, 0, 0.1);
                }
                .ql-container {
                    height: 15rem;
                    //padding: 0.2em 1em;
                    margin-inline: 1.5em;
                    margin-bottom: 1em;
                    scrollbar-width: thin;
                    border: none !important;
                    .ql-editor {
                        border-radius: 16px;
                        background-color: $primaryBGColor;
                        scrollbar-width: thin;
                    }
                    .ql-editor::-webkit-scrollbar {
                        width: 5px;
                    }
                    .ql-editor::-webkit-scrollbar-thumb {
                        background: $primaryColor;
                        border-radius: 16px;
                    }
                }
            }
            
        }
    }

    .class-usercard-flex {
        display: flex;
        gap: 30px;

        .product-bottomcard-main {
            padding: 40px 24px;
            background: #FFFFFF;
            border-radius: 16px;
            width: 50%;
            margin: 30px 0px 50px;

            .user-heading {
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                color: #131313;
            }

            .user-text {
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 500;
                color: #413D3D;
            }

            .product-form-group {
                border: 1px solid #F1F3F4;
                background: #F1F3F4;
                border-radius: 8px;

            }

            .pricing {
                .mb-3{
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                }
            }

        }

        .add-product-btn {
            padding: 10px 16px;
            gap: 8px;
            background: #2F49D1;
            border-radius: 8px;
            display: inline-block;
            color: #fff;
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
        }
    }
    .errors-after-sumbit {
        color: $primaryColor;
        text-align: center;
        font-weight: 600;
        font-family: 'Montserrat';
    }
  
}
@media only screen and (max-width: $breakpoint-tablet) {
    .product-main .class-usercard-flex {
        display: block;
    }
    .product-main .class-usercard-flex .product-bottomcard-main {
        width: 100%;
    }
    .product-main {
      .uploaded-images {
        justify-content: flex-start;
      }
      .user-topcard-main {
            .color-selectors {
                .color-box {
                    // width: 90%;
                    .custom-picker {
                        position: absolute;
                        right: 14.5em;
                        bottom: -1em;
                    }
                    
                }
            }
      }
      .topcard-description {
            .description-form-group {
                height: 450px !important;
                .ql-toolbar{
                    overflow-x: auto;
                    flex-wrap: wrap;
                    gap: 0.5em;
                }       
            }
      .description-form-group {
        height: 450px !important;
        .ql-toolbar {
          overflow-x: auto;
          flex-wrap: wrap;
          gap: 0.5em;
        }
      }
    }
  }
}
}

.css-syji7d-Group{
  border-bottom: 1px solid #cccccc70;
}

.modal-btn-div{
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.button-submit{
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  float: right;
  width: 25%;
 }