@import "../../assets//scss/variables.scss";

.faq-list-main {
  .faq-table-main {
    padding: 24px;
    background: #fff;
    border-radius: 16px 16px 0px 0px;
    margin-top: 34px;
    width: auto;

    .table-flex-main {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 50px;
    }

    .brand-table-head {
      margin-top: 40px;
      tr {
        th {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          text-transform: capitalize;
          color: #131313;
        }

        .width-140 {
          width: 140px;
        }
      }
    }
  }

  tbody {
    tr {
      .action-btn-main {
        display: flex;
        gap: 15px;
      }
    }
  }
}
.modal-header-custom {
  padding: 2.5rem 2rem;
  border: none;
}
.modal-body-custom {
  padding-top: 0;
}
.addBrand-main {
  margin: 0 40px 0 20px;

  .heading-text {
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 14px;
    line-height: 17.07px;
    color: "#131313";
  }

  .addBrand-input-form {
    font-family: "Montserrat";
    font-size: 14px;
    color: #413d3d;

    .input-container {
      margin-top: 16px;
      color: #413d3d;

      .input-title {
        display: flex;
        justify-content: space-between;
        margin-left: auto;
      }
      .uploaddocument-group {
        border-color: $primaryColor;
        margin-left: 0.5em;
        border-radius: 8px;
      }
    }

    Input,
    .input-textarea {
      background-color: #f5f2ed;
      border: none;
      margin-left: 0.5rem;
      font-size: 14px;
      border-radius: 8px;
      padding: 1rem;
    }
    .errors-after-sumbit {
      color: $primaryColor;
      text-align: center;
      font-weight: 600;
      font-family: "Montserrat";
    }

    .save-btn-container {
      display: flex;
      justify-content: center;
      margin-top: 30px;

      .save-btn {
        width: 300px;
      }
    }
  }
}

.brand-name-container{
  display: flex;
  align-items: center; 
  img{
    border-radius: 50%;
    width: 45px;
    height: 45px;
  }
   
}
