@import "../../assets/scss/variables.scss";

.product-main {
  width: 100%;
  .heading {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .heading-right-btn {
      display: flex;
      align-items: center;

      .select-role {
        margin-right: 30px;
        min-width: 15rem;
        input {
          min-width: 100% !important;
          background-color: #fff !important;
        }
        .css-1nmdiq5-menu {
          z-index: 999;
        }
      }
    }
  }
  .restaurants-list {
    padding: 0 !important;
    background-color: aqua;
  }
  .product-table-main {
    padding: 24px;
    margin-top: 34px;
    background: #ffffff;
    border-radius: 16px;

    .infinite-load-margin {
      margin-top: 50px;
    }

    .table-flex-main {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;
      //padding-block: 0.7em 3em;
      //padding-block: 1em 1em;
      padding: 0.1em;
      margin-bottom: 1em;
      position: sticky;
      top: 2em;
      z-index: 2;
      backdrop-filter: blur(5px);
      border-radius: 10px;

      
      .async-select {
        padding: 0.3em;
        min-width: 15rem;
      }

      .table-inner-flex {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        align-items: center;
        width: 100%;
      }

      .product-select {
        padding: 14px;
        background: $primaryBGColor;
        border-radius: 50px;
        border: none;
      }

      .table-search-width {
        width: $searchbar-max-width;

        .table-search-main {
          padding: 7px;
          background: $primaryBGColor;
          border-radius: 50px;

          .table-search-icon,
          .table-search-input {
            background: $primaryBGColor;
            border: none;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 999px) {
  .product-main .product-table-main .table-flex-main .table-inner-flex {
    width: 68%;
  }
}

@media only screen and (max-width: $breakpoint-mobile) {
  .product-main {
    .product-table-main {
      padding: 1.5em;

      .table-flex-main {
        padding-block: 0;
        margin-bottom: 1em;
        top: 3.5em;
        .table-inner-flex {
          width: unset;
          flex-wrap: nowrap;

          .product-select {
            border: 2px solid #f1f3f4;
          }
        }

        .table-search-width {
          width: min-content;

          .table-search-main {
            padding: 0.3em;
            border-radius: 50px;
            border: 2px solid #f1f3f4;
            width: min-content;
            flex-wrap: nowrap;

            .table-search-input {
              display: none;
            }

            .search-expand {
              width: 100px;
              border: none;
              /* position: absolute;
              left: 3em;
              top: 0; */
            }
          }
        }
      }
    }
  }
}
